import { Tooltip, TooltipProps } from '@mantine/core';
import classNames from 'classnames';
import React from 'react';

const StyledTooltip = React.forwardRef(
    (
        {
            children,
            w,
            classNames: tooltipClassNames,
            ...rest
        }: { children: JSX.Element } & TooltipProps,
        ref: React.Ref<HTMLDivElement>,
    ) => {
        const tooltipClassName = classNames(
            '!min-h-[45px] flex items-center bg-tooltip text-secondary !whitespace-normal p-3 normal-case !z-[99999999999]',
            tooltipClassNames?.['tooltip'],
        );
        return (
            <Tooltip
                w={w ?? 'auto'}
                classNames={{
                    ...tooltipClassNames,
                    tooltip: tooltipClassName,
                }}
                withArrow
                {...rest}
                zIndex={500}
            >
                {children}
            </Tooltip>
        );
    },
);
export default StyledTooltip;
