import { useMantineColorScheme } from '@mantine/core';
import { useColorScheme as useColorSchemeMantineMantine, useLocalStorage } from '@mantine/hooks';
import { useEffect } from 'react';
import { ColorScheme } from '@/types/general';
import { useAppContext } from '../contexts/appContext';
import { colorsScheme } from '../constants/colors';

function useColorScheme(): {
    colorScheme: ColorScheme;
    toggleColorScheme: () => void;
    colors: any;
} {
    const { user, rawUser } = useAppContext();
    const { colorScheme, toggleColorScheme, setColorScheme } = useMantineColorScheme();
    const preferredColorScheme = useColorSchemeMantineMantine();
    const [localStorageColorScheme] = useLocalStorage<ColorScheme>({
        key: 'color-scheme',
        defaultValue: 'dark',
    });

    const colors = colorsScheme[colorScheme];

    // useEffect(() => {
    //     // ? only For Arena Group
    //     if (!user?.groups?.includes('arena-staff')) {
    //         // if (colorScheme === 'dark') setColorScheme('light');
    //         return;
    //     }
    //     setColorScheme(preferredColorScheme);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [preferredColorScheme, user?.groups]);

    return {
        colorScheme: colorScheme === 'auto' ? 'light' : colorScheme,
        toggleColorScheme,
        colors,
    };
}

export default useColorScheme;
