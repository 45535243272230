import { useAppContext } from '@/contexts/appContext';
import { useUserAccess } from '@/hooks/useUserAccess';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { NavItem, PagesType } from './constants';
import { useNavItems } from './useNavItems';

// ? When it doesn't have href. we user the router pathName and redirect if the user group didn't match
function useHrefAccess({ href }: { href?: PagesType | string } = {}) {
    const { user } = useAppContext();
    const router = useRouter();
    const navItems = useNavItems();
    const testHref = href ?? router.pathname?.replace('/', '');
    const { pages, securePages } = useMemo(() => {
        const items = navItems.reduce(
            (acc, item) => ({
                hRefs: ['/'].includes(item.href) ? acc.hRefs : [...acc.hRefs, item.href],
                pageToObject: {
                    ...acc.pageToObject,
                    [item.href]: item,
                },
            }),
            { hRefs: [], pageToObject: {} as Record<string, NavItem> },
        );
        return {
            pages: items.pageToObject,
            securePages: items.hRefs,
        };
    }, [navItems]);
    const currentPage = pages?.[testHref];

    const hasAccess = useUserAccess({
        ruleOverRideWithOriginalGroup: currentPage?.ruleOverRideWithOriginalGroup,
        userGroupBlacklist: currentPage?.userGroupBlacklist,
        userGroupWhitelist: currentPage?.userGroupWhitelist,
    });
    const hasAccessToPage = useMemo(() => {
        if (typeof user?.groups === 'undefined' || currentPage?.overrideAccess) return true;
        if (!securePages.includes(testHref)) return true;
        if (user?.groups?.includes('arena-staff')) return true;
        return hasAccess;
    }, [currentPage?.overrideAccess, hasAccess, securePages, testHref, user?.groups]);

    return hasAccessToPage;
}

export default useHrefAccess;
