import React from 'react';

interface ArenaTextLogoProps {
    className?: string;
    size?: number;
}

export const ArenaTextLogo: React.FC<ArenaTextLogoProps> = ({ className, size = 100 }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        viewBox="0 0 65 12"
        fill="none"
        className={className}
    >
        <path d="M5.7385 0C6.76849 0 7.70038 0.0487806 8.53418 0.146342C9.36798 0.227643 10.3734 0.382114 11.5506 0.609756V11.6341H9.88298V10.6341C9.16362 11.1057 8.46879 11.4472 7.79848 11.6585C7.12817 11.8862 6.40881 12 5.64041 12C4.52868 12 3.53956 11.748 2.67306 11.2439C1.82291 10.7398 1.16078 10.0325 0.686658 9.12195C0.228886 8.21138 0 7.15447 0 5.95122C0 4.76423 0.228886 3.72358 0.686658 2.82927C1.16078 1.93496 1.83109 1.2439 2.69759 0.756098C3.56408 0.252033 4.57772 0 5.7385 0ZM5.95921 1.43902C4.5859 1.43902 3.52321 1.85366 2.77116 2.68293C2.03545 3.5122 1.6676 4.61789 1.6676 6C1.6676 7.36585 2.04363 8.47155 2.79568 9.31707C3.56408 10.1463 4.57772 10.561 5.8366 10.561C6.52326 10.561 7.17722 10.4553 7.79848 10.2439C8.43609 10.0325 9.13092 9.68293 9.88298 9.19512V1.7561C8.50966 1.54472 7.20174 1.43902 5.95921 1.43902Z" />
        <path d="M22.1043 1.73171C20.404 1.73171 18.859 1.81301 17.4694 1.97561V11.6341H15.8018V0.682927C17.9925 0.292683 20.0934 0.0975612 22.1043 0.0975612V1.73171Z" />
        <path d="M29.9302 0C30.9929 0 31.9329 0.219512 32.7504 0.658537C33.5678 1.0813 34.2054 1.69919 34.6632 2.5122C35.121 3.3252 35.3499 4.26829 35.3499 5.34146C35.3499 5.84553 35.3254 6.27642 35.2763 6.63415H25.8348C25.9655 7.80488 26.4151 8.73984 27.1835 9.43903C27.9683 10.1382 28.9819 10.4878 30.2245 10.4878C30.8948 10.4878 31.5406 10.439 32.1618 10.3415C32.7831 10.2276 33.527 10.065 34.3935 9.85366L34.6632 11.2195C33.764 11.4797 32.9874 11.6748 32.3335 11.8049C31.6795 11.935 30.9602 12 30.1754 12C28.9165 12 27.8212 11.748 26.8893 11.2439C25.9737 10.7236 25.2707 10.0081 24.7802 9.09756C24.2898 8.18699 24.0445 7.14634 24.0445 5.97561C24.0445 4.78862 24.2898 3.74797 24.7802 2.85366C25.2871 1.94309 25.9819 1.2439 26.8647 0.756098C27.7639 0.252033 28.7857 0 29.9302 0ZM29.9547 1.36585C28.7612 1.36585 27.7885 1.72358 27.0364 2.43902C26.3007 3.15447 25.8838 4.11382 25.7857 5.31707H33.8294C33.8131 4.09756 33.4452 3.13821 32.7259 2.43902C32.0229 1.72358 31.0991 1.36585 29.9547 1.36585Z" />
        <path d="M44.9491 0C46.3878 0 47.5159 0.414634 48.3334 1.2439C49.1508 2.05691 49.5595 3.17886 49.5595 4.60976V11.6341H47.8919V5C47.8919 3.91057 47.5977 3.06504 47.0091 2.46341C46.4369 1.84553 45.6439 1.53659 44.6303 1.53659C43.1262 1.53659 41.7202 1.65041 40.4123 1.87805V11.6341H38.7447V0.609756C39.5785 0.430894 40.5676 0.284553 41.712 0.170732C42.8564 0.0569106 43.9355 0 44.9491 0Z" />
        <path d="M58.4736 0C59.5036 0 60.4355 0.0487806 61.2693 0.146342C62.1031 0.227643 63.1086 0.382114 64.2857 0.609756V11.6341H62.6181V10.6341C61.8988 11.1057 61.2039 11.4472 60.5336 11.6585C59.8633 11.8862 59.1439 12 58.3755 12C57.2638 12 56.2747 11.748 55.4082 11.2439C54.558 10.7398 53.8959 10.0325 53.4218 9.12195C52.964 8.21138 52.7351 7.15447 52.7351 5.95122C52.7351 4.76423 52.964 3.72358 53.4218 2.82927C53.8959 1.93496 54.5662 1.2439 55.4327 0.756098C56.2992 0.252033 57.3129 0 58.4736 0ZM58.6943 1.43902C57.321 1.43902 56.2583 1.85366 55.5063 2.68293C54.7706 3.5122 54.4027 4.61789 54.4027 6C54.4027 7.36585 54.7788 8.47155 55.5308 9.31707C56.2992 10.1463 57.3129 10.561 58.5717 10.561C59.2584 10.561 59.9123 10.4553 60.5336 10.2439C61.1712 10.0325 61.8661 9.68293 62.6181 9.19512V1.7561C61.2448 1.54472 59.9369 1.43902 58.6943 1.43902Z" />
    </svg>
);
