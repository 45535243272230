import { useCallback, useMemo, useState } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';

interface GlobalContextType {
    isMobileNavOpen: boolean;
    navbarIsHovering: boolean;
    openMobileNav: () => void;
    closeMobileNav: () => void;
    toggleMobileNav: () => void;
    updateIsNavbarHovering: (isHovering: boolean) => void;
}

const GlobalContext = createContext<GlobalContextType>({} as GlobalContextType);

export const GlobalContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [navbarIsHovering, setNavbarIsHovering] = useState(false);
    const [isMobileNavOpen, setMobileNavIsOpen] = useState(false);

    const openMobileNav = useCallback(() => {
        setMobileNavIsOpen(true);
    }, []);

    const closeMobileNav = useCallback(() => {
        setMobileNavIsOpen(false);
    }, []);

    const toggleMobileNav = useCallback(() => {
        if (isMobileNavOpen) {
            closeMobileNav();
        } else {
            openMobileNav();
        }
    }, [isMobileNavOpen, closeMobileNav, openMobileNav]);

    const value = useMemo<GlobalContextType>(
        () => ({
            isMobileNavOpen,
            openMobileNav,
            closeMobileNav,
            toggleMobileNav,
            navbarIsHovering,
            updateIsNavbarHovering: setNavbarIsHovering,
        }),
        [
            isMobileNavOpen,
            navbarIsHovering,
            openMobileNav,
            closeMobileNav,
            setNavbarIsHovering,
            toggleMobileNav,
        ],
    );

    return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};

export const useGlobalContext = <T,>(selector: (state: GlobalContextType) => T) =>
    useContextSelector(GlobalContext, selector);
