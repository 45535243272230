import { ColorScheme } from '@/types/general';
import Color from 'color';
import _ from 'lodash';

const colors = {
    boldBlue: '#123FDF',
    tropicalBlue: '#0EBFF6',
    teal: '#35DFD5',
    magenta: '#C839DF',
    purple: '#8042E3',
    red: '#DF2960',
    orange: '#F07039',
    yellow: '#EBB938',
    green: '#54E85A',
    darkGreen: '#08AC0E',
    arenaBlue: '#295DEE',
};

const primaryColors = {
    pink: '#F11B97',
    purple: '#6938B9',
    blue: '#345DEE',
    lightGreen: '#6CFFA7',
};
const grayScale = {
    0: '#EEEEF2',
    20: '#E4E4E8',
    40: '#D7D9DE',
    60: '#B1B5C1',
    80: '#81859B',
    100: '#515674',
};

const colorScales = {
    blue: {
        0: '#345DEE',
        10: '#2F54D6',
        20: '#2643AB',
        30: '#1C3280',
    },
    pink: {
        0: '#BB54B5',
        10: '#A84CA3',
        20: '#863D82',
        30: '#652E62',
    },
    reddishPink: {
        0: '#E0648A',
        10: '#CA5A7C',
        20: '#A14863',
        30: '#79364A',
    },
    orange: {
        0: '#EC8263',
        10: '#D47559',
        20: '#AA5E47',
        30: '#7F4635',
    },
    yellow: {
        0: '#EBA338',
        10: '#D39332',
        20: '#A97628',
        30: '#7F581E',
    },
    greenYellow: {
        0: '#97A137',
        10: '#889131',
        20: '#6D7427',
        30: '#52571D',
    },
    green: {
        0: '#4C9355',
        10: '#44844C',
        20: '#366A3D',
        30: '#294F2E',
    },
    darkGreen: {
        0: '#007D6D',
        10: '#007062',
        20: '#005A4E',
        30: '#00433B',
    },
    teal: {
        0: '#0082AC',
        10: '#00759B',
        20: '#005E7C',
        30: '#00465D',
    },
};

export const andromedaColors = _.map(colorScales, c => _.values(c)[0]);

const colorScaleAndGradient = [
    colorScales.blue[0],
    colorScales.pink[0],
    colorScales.reddishPink[0],
    colorScales.orange[0],
    colorScales.yellow[0],
];

const divergentScales = {
    positive: {
        0: '#B6BDFE',
        40: '#7188FD',
        70: '#3253E1',
        100: '#0024A4',
    },
    negative: {
        0: '#FCAFB5',
        30: '#F16475',
        70: '#C52237',
        100: '#7F0006',
    },
    positiveLight: {
        0: '#C5CAFE',
        40: '#8DA0FD',
        70: '#5B75E7',
        100: '#3350B6',
    },
    negativeLight: {
        0: '#FDBFC4',
        30: '#F48391',
        70: '#D14E5F',
        100: '#993338',
    },
};

const categoryColors = [
    '#3054d6',
    '#a94ca3',
    '#ca5b7c',
    '#d47559',
    '#d49332',
    '#889131',
    '#44844d',
    '#027d6d',
    '#0082ac',
];

const usWholesalerColors = [
    '#35DFD5',
    '#DF2960',
    '#F07039',
    '#EBB938',
    '#54E85A',
    '#08AC0E',
    '#0EBFF6',
    '#C839DF',
    '#6CFFA7',
    '#F11B97',
];

const categoryColorsLight = [
    '#345DEE',
    '#BB54B5',
    '#E0648A',
    '#EC8263',
    '#EBA338',
    '#97A137',
    '#4C9355',
    '#007D6D',
    '#0082AC',
];

const getColorByIndex = (colorsParam: string[], index: number): string =>
    colorsParam[index % colorsParam.length];

export const getRandomColor = () => {
    const hue = Math.floor(Math.random() * 360);
    const saturation = 75 + Math.floor(Math.random() * 25);
    const lightness = 65 + Math.floor(Math.random() * 20);

    const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;

    return color;
};

export const getPrimaryColor = (alpha = 1) => {
    const color = 'rgb(var(--primary-color))';
    return Color(color).alpha(alpha).string();
};

const colorsScheme: {
    [key in ColorScheme]: {
        'primary-color': string;
        'secondary-color': string;
        'border-color': string;
        'secondary-bg': string;
        shadow: string;
        'navy-solid-5': string;
        'navy-solid-10': string;
        'navy-solid-15': string;
        'navy-solid-30': string;
        'navy-solid-50': string;
        'navy-solid-70': string;
        'spinner-color': string;
        'shadow-default-opacity': string;
        polarisGreen: string;
        navy: string;
        'card-gradient-hex': string;
        'card-gradient-hex-first-color': string;
        'table-cell-color': string;
    };
} = {
    light: {
        'primary-color': '#1a1f26',
        'secondary-color': '#e8e8e8',
        'border-color': 'rgba(0, 0, 0, 0.1)',
        'secondary-bg': '#f9f9f9',
        shadow: '64, 68, 74',
        'navy-solid-5': '#EEEEF2',
        'navy-solid-10': '#e4e4e8',
        'navy-solid-15': '#d7d9de',
        'navy-solid-30': '#b1b5c1',
        'navy-solid-50': '#81859b',
        'navy-solid-70': '#515674',
        'spinner-color': '#ff69b4',
        polarisGreen: '#4DE188',
        'shadow-default-opacity': '0.05',
        'card-gradient-hex': 'linear-gradient(113deg, #f9f9f9 0%, #f0eaf3 100%)',
        'card-gradient-hex-first-color': '#f9f9f9',
        navy: '#08103a',
        'table-cell-color': '#f9f9f9',
    },
    dark: {
        'primary-color': '#fdfdfd',
        'secondary-color': '#181d24',
        'border-color': '#515674',
        'secondary-bg': '#2a2f36',
        shadow: '10, 12, 15',
        'navy-solid-5': '#2a2f36',
        'navy-solid-10': '#303344',
        'navy-solid-15': '#81859b',
        'navy-solid-30': '#b1b5c1',
        'navy-solid-50': '#d7d9de',
        'navy-solid-70': '#e4e4e8',
        'spinner-color': '#BB54B5',
        polarisGreen: '#6cffa7',
        'shadow-default-opacity': '0.25',
        'card-gradient-hex': 'linear-gradient(135deg, #333d4c 0%, #1f242e 101.75%)',
        'card-gradient-hex-first-color': '#333d4c',
        'table-cell-color': '#19202e',
        navy: '#6182f7',
    },
};

export default colors;
export {
    primaryColors,
    grayScale,
    colorScales,
    colorScaleAndGradient,
    divergentScales,
    categoryColors,
    categoryColorsLight,
    getColorByIndex,
    colorsScheme,
    usWholesalerColors,
};
