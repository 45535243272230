import Head from 'next/head';
import React, { useEffect } from 'react';
import isMobile from 'ismobilejs';
import classNames from 'classnames';
import { ArrowsCounterClockwise } from 'phosphor-react';
import NavBar from './navBar/navBar';
import ReactHeap from '../utils/heap';
import { HEAP_ID } from '../buildConfig/processEnv';
import { useAppContext } from '../contexts/appContext';
import Login from './login';
import AppLoading from './appLoading';
import MobileNavBar from './MobileNavBar';
import useColorScheme from '../hooks/useColorScheme';
import useHrefAccess from './navBar/useHrefAccess';

const DETAIL_OPTIONS = ['true', 'skip'];

function Layout({
    children,
    title,
    className,
    style = {},
}: {
    children: React.ReactNode;
    title: string;
    className?: string;
    style?: React.CSSProperties;
}) {
    const { rawUser, user, fetchingToken, handleUser } = useAppContext();

    const { colorScheme } = useColorScheme();
    useHrefAccess();

    useEffect(() => {
        if (HEAP_ID) {
            ReactHeap.initialize(HEAP_ID);
        }
    }, []);

    const mobile = isMobile().phone;

    if (fetchingToken) {
        return <AppLoading>Authenticating user</AppLoading>;
    }

    if (!user || !rawUser || (!user.token && !user.siberiaToken) || !user.isLoggedIn) {
        return <Login />;
    }

    if (!user?.groups && !!user?.siberiaToken && !rawUser?.groups) {
        return <AppLoading>Loading user data</AppLoading>;
    }

    return (
        <div
            className={classNames({
                dark: colorScheme === 'dark',
            })}
        >
            <Head>
                <title>{title}</title>
                <link rel="shortcut icon" href="/images/favicon.png" />
            </Head>
            {/* <RegisterForm setIsDetails={setIsDetails} isDetails={isDetails} /> */}
            <div className="flex">
                <div
                    className={classNames('fixed z-[9999]', {
                        hidden: mobile,
                    })}
                >
                    <NavBar />
                </div>
                {mobile && user?.token && !user?.siberiaToken && (
                    <div>
                        <MobileNavBar />
                    </div>
                )}
                <div
                    style={style}
                    className={classNames(`p-4 ml-20 grow`, className, {
                        '!ml-0 max-w-[100dvw] overflow-auto': mobile,
                    })}
                >
                    {children}
                </div>
            </div>
            {rawUser?.groups?.includes('arena-staff') &&
                user.displayDeveloperTools === 'show' &&
                !rawUser?.groups?.some(g => user?.groups?.includes(g)) && (
                    <div className="absolute bottom-0 z-[9999999999999] flex h-8 w-full items-center justify-center bg-risd-blue">
                        Your are overriding default user group to use default user group{' '}
                        <button
                            type="button"
                            onClick={() => handleUser({ groups: rawUser.groups })}
                            className="ml-2"
                            aria-label="reset user groups"
                        >
                            <ArrowsCounterClockwise />
                        </button>
                    </div>
                )}
        </div>
    );
}

export default Layout;
