import { useRouter } from 'next/router';
import { useEffect } from 'react';
import Link from 'next/link';
import cx from 'classnames';
import { Text } from '@mantine/core';
import { useAnimate } from 'framer-motion';
import { ARENA_DEV_ROUTES, NavItem, PAGE_NAMES, PagesType, TBD_PAGE_NAMES } from './constants';
import StyledTooltip from '../utils/styledTooltip';
import { useUserAccess } from '../../hooks/useUserAccess';
import { useAppContext } from '../../contexts/appContext';
import useHrefAccess from './useHrefAccess';

const covertHrefToRoute = (href: string) => {
    const index = href.indexOf('?');
    if (index > -1) {
        return href.substring(0, index);
    }
    if (href.includes('/')) return href;
    return `/${href}`;
};

export const ActiveLink = ({
    href,
    children,
    className = '',
    sharableKeys = [],
    userGroupWhitelist = [],
    userGroupBlacklist = [],
    isHovering,
    customLabel,
    tooltipLabel,
    disabled: disbledProp,
    isLogo,
    ruleOverRideWithOriginalGroup,
}: NavItem) => {
    const { pathname, ...router } = useRouter();
    const { user } = useAppContext();

    const hasAccessToHref = useHrefAccess({ href });
    const isArenaStaff = useUserAccess({ userGroupWhitelist: ['arena-staff'] });
    const isTBDStaff = useUserAccess({ userGroupWhitelist: ['bausch-users'] });

    const disabled = !isArenaStaff && disbledProp;

    const [scope, animate] = useAnimate();

    useEffect(() => {
        if (!scope.current || !router.isReady) return;
        if (isHovering) {
            animate(scope.current, { width: 'auto' });
        } else {
            animate(scope.current, { width: 0 });
        }
    }, [isHovering, animate, scope, router.isReady]);

    const hrefWithParams = (() => {
        if (sharableKeys.length === 0) return `${href.includes('/') ? href : `/${href}`}`;

        const commonQueries = sharableKeys.reduce((acc: string, key) => {
            const value = router?.query?.[key];
            if (!value) return acc;
            const val = Array.isArray(value) ? value.join(`&${key}=`) : value;
            return `${acc}${acc.includes('?') ? `&${key}=${val}` : `?${key}=${val}`}`;
        }, '');
        return `${href.includes('/') ? href : `/${href}`}${commonQueries}`;
    })();

    const label =
        // ? This change is to make sure when we have more than 1
        customLabel ??
        (isTBDStaff ? TBD_PAGE_NAMES?.[href] ?? PAGE_NAMES?.[href] : PAGE_NAMES?.[href]);

    const isActive =
        (covertHrefToRoute(href) === pathname ||
            pathname.includes(`${covertHrefToRoute(href)}/`)) &&
        !isLogo;

    if (!hasAccessToHref) return null;

    return (
        <div
            id={hrefWithParams}
            className={cx({
                'text-risd-blue border-r-2 border-risd-blue': isActive,
                hidden: user.displayDeveloperTools === 'hide' && ARENA_DEV_ROUTES.includes(href),
            })}
        >
            <Link
                href={disabled ? {} : hrefWithParams}
                className={cx(
                    'p-1.5 flex items-center hover:opacity-100',
                    {
                        'cursor-not-allowed': disabled,
                    },
                    className,
                )}
            >
                <StyledTooltip
                    position="right"
                    withArrow
                    classNames={{
                        tooltip: '!whitespace-nowrap',
                    }}
                    w="auto"
                    arrowSize={12}
                    label={tooltipLabel}
                    disabled={!tooltipLabel}
                >
                    <div
                        className={cx('flex items-center gap-1 justify-center', {
                            'opacity-50': !isLogo && !isActive,
                            'hover:opacity-100 hover:cursor-pointer': !disabled,
                            'text-navy-solid-70': disabled,
                        })}
                    >
                        <div className={cx({ 'flex flex-row': isLogo })}>{children}</div>
                        <div
                            ref={scope}
                            className={cx('overflow-hidden whitespace-nowrap', {
                                'w-0': !isHovering,
                            })}
                        >
                            <Text
                                className="pl-3"
                                style={{
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    lineHeight: 'normal',
                                }}
                            >
                                {label}
                            </Text>
                        </div>
                    </div>
                </StyledTooltip>
            </Link>
        </div>
    );
};
