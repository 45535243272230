import useColorScheme from '@/hooks/useColorScheme';
import { Global } from '@emotion/react';
import React from 'react';
import { getGlobalCss } from 'styles/getGlobalCss';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.layer.css';
import '@mantine/notifications/styles.css';

function RenderGlobalCSS() {
    const { colorScheme } = useColorScheme();
    return <Global styles={getGlobalCss(colorScheme)} />;
}

export default RenderGlobalCSS;
