import { useAppContext } from '../contexts/appContext';
import { UserGroup } from '../types/user';

interface UseUserAccessParams {
    userGroupWhitelist?: UserGroup[] | UserGroup;
    userGroupBlacklist?: UserGroup[] | UserGroup;
    ruleOverRideWithOriginalGroup?: boolean;
}

export const useUserAccess = ({
    userGroupWhitelist = [],
    userGroupBlacklist = [],
    ruleOverRideWithOriginalGroup,
}: UseUserAccessParams) => {
    const {
        user: { groups },
        rawUser,
    } = useAppContext();

    if (!groups || !rawUser?.groups) {
        return false;
    }

    const inneruserGroupWhitelist = Array.isArray(userGroupWhitelist)
        ? userGroupWhitelist
        : [userGroupWhitelist];

    const innerUserGroupBlacklist = Array.isArray(userGroupBlacklist)
        ? userGroupBlacklist
        : [userGroupBlacklist];

    const hasAccessByGroups = (userGroups?: UserGroup[]) =>
        (inneruserGroupWhitelist.length === 0 ||
            userGroups?.some(group => inneruserGroupWhitelist.includes(group))) &&
        !userGroups?.some(group => innerUserGroupBlacklist.includes(group));

    if (ruleOverRideWithOriginalGroup) {
        return hasAccessByGroups(rawUser?.groups);
    }

    return hasAccessByGroups(groups);
};
