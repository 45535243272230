import React from 'react';

function AppLoading({ children }: { children: string }) {
    return (
        <div className="flex h-screen w-full items-center justify-center text-center">
            <p className="w-96 text-navy-solid-70">{children}</p>
        </div>
    );
}

export default AppLoading;
